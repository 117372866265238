<template>
  <div class="add-pro-wrapper">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="添加音效"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="add-pro-content">
      <el-row :gutter="15">
      <!-- 左模块 -->
        <el-col :span="18">
          <!-- 基本信息 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="4">
                <div class="part-info">
                  <div class="title">基本</div>
                  <div class="info">音效信息</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="20">
                <el-row :gutter="20">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.id" :disabled="true">
                        <template slot="prepend">ID</template>
                      </el-input>
                  </el-col>
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.download_times" :disabled="true">
                        <template slot="prepend">下载次数</template>
                      </el-input>
                  </el-col>
                </el-row>

                <!-- 名称 -->
                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.name">
                        <template slot="prepend">名称</template>
                      </el-input>
                  </el-col>
                </el-row>
                <!-- 完整地址 -->
                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.full_path" :disabled="false">
                        <template slot="prepend">外链地址</template>
                      </el-input>
                  </el-col>
                </el-row>

                <el-collapse>
                <el-collapse-item title="更多" name="1">
                  <!-- 自定义域名 -->
                  <el-row v-if="!isLinkType" :gutter="20" class="mt10">
                    <el-col :span="24">
                        <el-input placeholder="请输入内容" v-model="form.hostname" :disabled="false">
                          <template slot="prepend">hostname</template>
                        </el-input>
                    </el-col>
                  </el-row>
                  <!-- 地址 -->
                  <el-row v-if="!isLinkType" :gutter="20" class="mt10">
                    <el-col :span="24">
                        <el-input placeholder="请输入内容" v-model="form.path" :disabled="false">
                          <template slot="prepend">地址</template>
                        </el-input>
                    </el-col>
                  </el-row>
                  <!-- md5 -->
                  <el-row v-if="!isLinkType" :gutter="20" class="mt10">
                    <el-col :span="24">
                        <el-input placeholder="请输入内容" v-model="form.file_md5" :disabled="false">
                          <template slot="prepend">md5</template>
                        </el-input>
                    </el-col>
                  </el-row>
                  <!-- 文件名 -->
                  <el-row :gutter="20" class="mt10">
                    <el-col :span="24">
                        <el-input placeholder="请输入内容" v-model="form.file_name" :disabled="false">
                          <template slot="prepend">文件名</template>
                        </el-input>
                    </el-col>
                  </el-row>
                </el-collapse-item>
                </el-collapse>

                <!-- 内容描述 -->
                <el-input
                  type="textarea"
                  style="margin-top:10px"
                  :autosize="{ minRows: 4, maxRows: 6}"
                  placeholder="请输入内容"
                  v-model="form.discription">
                </el-input>

              </el-col>
            </el-row>
          </el-card>

          <!-- 文件上传 -->
          <el-card class="box-card" v-if="!isLinkType">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="3">
                <div class="part-info">
                  <div class="title">文件</div>
                  <div class="info">文件上传</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="21">
                <el-upload
                  class="upload-demo"
                  drag
                  action=""
                  ref="upload"
                  :auto-upload="false"
                  name="soundFile"
                  :multiple="true"
                  :limit="1"
                  :on-change="uploadChange"
                  :on-remove="fileRemove"
                  :on-exceed="fileExceed"
                  :on-preview="handlePreview"
                  >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传mp3/wav/ogg文件，且不超过10m</div>
                </el-upload>
                <!-- <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->
              </el-col>
            </el-row>
          </el-card>
          <el-card class="box-card">
            <Player :currSrc="currSrc" ref="player"></Player>
          </el-card>
        </el-col>

        <!-- 右模块 -->
        <el-col :span="6">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>状态</span>
            </div>
            <p class="p-extra mb5">启用状态</p>
            <el-select v-model="form.status" placeholder="请选择账号状态" style="width:100%" class="mb10">
              <el-option
                v-for="item in status_arr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
            <p class="p-extra mb5">推荐状态</p>
            <el-select v-model="form.recommend" placeholder="请选择推荐状态" style="width:100%" class="mb10">
              <el-option
                v-for="item in status_arr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
            <p class="p-extra mb5">分类</p>
            <el-select v-model="form.category_id" placeholder="请选择分类" style="width:100%">
              <el-option
                v-for="item in category_list"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>

            <el-input placeholder="请输入内容" v-model="form.wnb" style="width:100%;margin-top:10px">
              <template slot="prepend">下载所需币</template>
            </el-input>
          </el-card>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
import Player from "@/components/Player.vue"
export default {
  name:'addProducts',
    components:{
      Title,
      Player
    },
    data(){
      return{
        form:{
          wnb:0,
          status:1,
          recommend:1,
        },
        status_arr:[
          {name:'启用/激活/推荐',value:1},
          {name:'禁用/未激活/不推荐',value:0},
          {name:'未设置',value:null},
        ],   
        devices_list:[],
        category_list:[],
        currSrc:""
      }
    },
    methods:{
      save(){
        console.log(this.form)
        const formArr = Object.entries(this.form)
        console.log(formArr)
        let formData = new FormData();
        formArr.forEach(item=>{
          if(item[1] != null){
            formData.append(item[0], item[1]);
          }
        })
        apis.sounds.create(formData).then(res=>{
          console.log('res',res)
          if(res.data.code == 1){
            this.$message({message:"创建成功", type:"success"})
            this.$router.push({name:'soundsList'})
          }else{
            this.$message({message:res.data.msg,type:'error'})
          }

        }).catch(err=>{
          this.$message({message:err,type:'error'})
        })
      },
      getCategoryList(){
        apis.category.read(0).then(res=>{
          console.log('res',res)
          this.category_list = res.data.data
        }).catch(err=>{
          console.log('err',err)
        })
      },

      handlePreview(file) {
        let f = file.raw;
        this.$refs.player.play({
          src:window.URL.createObjectURL(f),
          name:f.name
        })
      },
      uploadChange(file){
        console.log("uploadChange",file)
        this.$set(this.form,'sound',file.raw)
        this.$set(this.form,'name',file.name.split('.')[0])
      },
      fileExceed(){
        this.$message({type:"info",message:"一次只能上传一个"})
      },
      fileRemove(){
        console.log("fileRemove")
        this.$set(this.form,'sound',null)
      }
    },
    mounted(){
        this.getCategoryList()
        console.log('mounted',this.$route)
    },
    created(){
      console.log('created',this.$route)
    },
    computed:{
      isLinkType(){
        return !!this.form.full_path
      }
    },
    watch:{
    }
}
</script>

<style lang="scss">
.add-pro-wrapper{
  .add-pro-content{
    box-sizing: border-box;
    width: 100%;
    padding:15px;
  }
  .box-card{
    margin-bottom:5px;
  }
  .part-info{
    // @include flex_ccc();
    // background: brown;
    .title{
      font-size: 18px;
      color:#333;
    }
    .info{
      font-size: 12px;
      color:#999;
    }
  }
}
</style>